var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "team-gallery__photos" },
    [
      _vm._l(_vm.photos, function(photo) {
        return [
          _c("div", { staticClass: "team-gallery__photo" }, [
            _c(
              "a",
              { attrs: { href: photo.url, "data-fancybox": "gallery" } },
              [
                _c("img", {
                  staticClass: "lazyload",
                  attrs: { "ix-src": photo.url, sizes: "28vw" }
                })
              ]
            )
          ])
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }