function resizeToCover() {
  // set the video viewport to the parent size
  var w = jQuery('.movie__video-wrapper').width();
  jQuery('video').width(w);

  // and center it by scrolling the video viewport
  jQuery('.movie__video-wrapper').scrollLeft((jQuery('video').width() - jQuery('.movie__video-wrapper').width()) / 2);
};

$( document ).ready( function() {
  jQuery(window).resize(function () {
    resizeToCover();
  });
  
  jQuery(window).trigger('resize');
});
