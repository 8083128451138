import Vue from 'vue';
import Gallery from './Gallery.vue';
import './styles/team-gallery.scss';
import './styles/fancybox.min.css';
import './fancybox.min.js';

$( document ).ready( function() {

  ////dekeo.imgix.net/testimonials/Inge_Testimonial_Pic.png
  new Vue({
    el: '#jsGallery',
    render: h => h(Gallery)
  });

  // reprocess imgix images
  imgix.init({
    force: true
  });
});
