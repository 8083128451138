$( document ).ready( function() {
  var $imgs = $(".js-img");
  if ($imgs.length) {
    console.log('in cond');

    // triggered whenever an image is loaded, including on resize
    $imgs.each(function(i, element) {
      $( this ).on('load', function(){
        $img = $( this );

        // fallback to src for browsers without srcset support
        var src = $img.prop('currentSrc') || $img.prop('src');
        // set the background image for the parent
        var $parent = $img.parent();
        $img.remove();

        console.log('new src: ' + src);
        $parent.css('backgroundImage', 'url('+src+')');
      });
    })
  }
});
