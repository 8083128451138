import './styles/index.scss';
import './styles/quote.scss';
import './styles/top-header.scss';
import './styles/top-banner.scss';
import './styles/top-image.scss';
import './styles/long-read.scss';
import './styles/who-we-are.scss';
import './styles/who-we-need.scss';
import './styles/what-we-value.scss';
import './styles/customers-review.scss';
import './styles/testimonials.scss';
import './styles/why-join.scss';
import './styles/careers.scss';
import './styles/locations.scss';
import './styles/movie.scss';


import 'imgix.js/dist/imgix.js';
import 'lazysizes/lazysizes.min.js';

// copy index.html to dist
require('file-loader?name=[name].[ext]!./index.html');

// placeholder for dekeo js files
import './imgix_bg.js';
import './swipe.js';
import './video.js';
import './gallery.js';
