<template>
  <div class="team-gallery__photos">
    <template v-for="photo in photos">
      <div class="team-gallery__photo">
        <a v-bind:href="photo.url" data-fancybox="gallery">
          <img
		        class="lazyload"
			      v-bind:ix-src="photo.url"
            sizes="28vw"
			    />
        </a>
      </div>
    </template>
  </div>
</template>

<!-- script -->
<script>
 export default {
   data () {
     const buildUrl = (name) => {
       return `//dekeo.imgix.net/team-gallery/${name}?fit=crop&crop=center`;
     }

     const imageNames = [ 'sq_lexa.jpg',
                          'sq_ashish.jpg',
                          'sq_austin_erica.jpg',
                          'sq_ayan_lori.jpg',
                          'sq_cst_biking.jpg',
                          'sq_cst_disney.jpg',
                          'sq_cst_orlando.jpg',
                          'sq_dev_basketball.jpg',
                          'sq_josh_subhash.jpg',
                          'sq_dev_hiking.jpg',
                          //'sq_dmitrii.jpg',
                          'sq_hornblower_niagara.jpg',
                          'sq_inge_scooter.jpg',
                          'dima_and_lexa_doing_some_work.jpg',
                          'dekeo_canada.png',
                          'phuket_office_talk.jpg'];

     const getList = () => {
       return imageNames.map((name) => {
         return { url : buildUrl(name) }
       });
     }
     
     return {
       photos: getList()
     }
   }
 }
</script>
